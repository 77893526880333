import isInteger from 'lodash/isInteger';
import { FINANCING_OPTIONS, MILEAGE_YEAR_PRICE_SETTINGS } from '@/constants/constants-financing';

const INITIAL_MILEAGE_YEAR_PRICE_SETTINGS_STATE_ITEM = () => ({
  settings: {},
});

const MileageYearPriceSettingsStore = {
  state: {
    mileageYearPriceStates: {},
  },
  getters: {
    getMileageYearPriceStateSettings: (state, getters) => (
      marketModelId = getters.getModelSelectedMarketModelId(),
    ) => state.mileageYearPriceStates[marketModelId]?.settings ?? {},

    getOptionMileageYearPriceSettings: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const stateSettings = getters.getMileageYearPriceStateSettings(marketModelId);
      const selectedFinancingOption = getters.getSelectedFinancingOption({
        financingStateKey: getters.getMarketModelModelId(marketModelId),
      });

      if (
        !!stateSettings[marketModelOptionId]
        && selectedFinancingOption === FINANCING_OPTIONS.leasing
      ) {
        const {
          annualMileage,
          leasePeriod: period,
        } = getters.getFinancingSettings(marketModelId)?.[FINANCING_OPTIONS.leasing] ?? {};

        return {
          annualMileage,
          period,
        };
      }

      return stateSettings[marketModelOptionId];
    },

    isOptionMileagesUnlimited: (state, getters) => ({
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
    } = {}) => {
      const mainOption = getters.getMarketModelOptionById({ marketModelId, marketModelOptionId });

      if (!mainOption) return false;

      const { showUnlimitedMileages = false } = mainOption?.financing ?? {};

      if (!showUnlimitedMileages) return false;

      const selectedAdditionalOptions = getters.getSelectedAdditionalOptions({ marketModelId, marketModelOptionId }) ?? [];

      if (!selectedAdditionalOptions.length) return false;

      const optionsToCheck = [
        mainOption,
        ...selectedAdditionalOptions,
      ];

      const period = getters.getOptionMileageYearPriceSettings({
        marketModelId,
        marketModelOptionId,
      })?.[MILEAGE_YEAR_PRICE_SETTINGS.period];

      const totalAnnualMileagePrices = {};
      optionsToCheck.forEach((option) => {
        const {
          annualMileages = [],
        } = option?.financing?.mileageYearPrices ?? {};

        annualMileages.forEach((mileageObj) => {
          const price = mileageObj?.priceYears?.find?.((priceYearObj) => priceYearObj.period === period)?.cashPrice;
          totalAnnualMileagePrices[mileageObj.mileage] = (totalAnnualMileagePrices[mileageObj.mileage] ?? 0) + price;
        });
      });

      const uniquePricesPerMileage = [...new Set(
        Object.values(totalAnnualMileagePrices),
      )];

      return uniquePricesPerMileage?.length <= 1;
    },
  },
  mutations: {
    RESET_MILEAGE_YEAR_PRICE_STATE_SETTINGS(state, marketModelId) {
      this._vm.$set(
        state.mileageYearPriceStates,
        marketModelId,
        INITIAL_MILEAGE_YEAR_PRICE_SETTINGS_STATE_ITEM(),
      );
    },

    UPDATE_MILEAGE_YEAR_PRICE_STATE_SETTING(state, {
      marketModelId,
      marketModelOptionId,
      annualMileage,
      period,
    } = {}) {
      if (!isInteger(annualMileage) || !isInteger(period)) return;

      this._vm.$set(
        state.mileageYearPriceStates[marketModelId].settings,
        marketModelOptionId,
        { annualMileage, period },
      );
    },

  },
  actions: {
    async initMileageYearPriceStateSettings({
      getters, commit,
    }, marketModelId = getters.getModelSelectedMarketModelId()) {
      commit('RESET_MILEAGE_YEAR_PRICE_STATE_SETTINGS', marketModelId);

      const options = getters.getMarketModelOptions({ marketModelId, ignoreFilter: true });

      options.forEach((option) => {
        if (
          !getters.isOptionPriceMileageYearBased({ marketModelId, marketModelOptionId: option.id })
        ) return;

        const config = option?.financing?.mileageYearPrices;
        const { annualMileages, defaultPeriod } = config;

        const defaultMileageObj = annualMileages.find((annualMileage) => !!annualMileage.default);

        commit('UPDATE_MILEAGE_YEAR_PRICE_STATE_SETTING', {
          marketModelId,
          marketModelOptionId: option.id,
          annualMileage: defaultMileageObj.mileage,
          period: defaultPeriod,
        });
      });
    },

    async setOptionMileageYearPriceSettings({ getters, commit, dispatch }, {
      marketModelId = getters.getModelSelectedMarketModelId(),
      marketModelOptionId,
      annualMileage,
      period,
      updateConfiguratorKey = true,
    } = {}) {
      const itemSettings = getters.getOptionMileageYearPriceSettings({
        marketModelId,
        marketModelOptionId,
      });

      const updatedMileage = annualMileage ?? itemSettings.annualMileage;
      const updatedYears = period ?? itemSettings.period;

      const update = (updateId) => {
        commit('UPDATE_MILEAGE_YEAR_PRICE_STATE_SETTING', {
          marketModelId,
          marketModelOptionId: updateId,
          annualMileage: updatedMileage,
          period: updatedYears,
        });
      };

      update(marketModelOptionId);

      const option = getters.getMarketModelOptionById({ marketModelId, marketModelOptionId });

      const additionalOptionIds = option?.additionalOptionsData?.options;

      if (Array.isArray(additionalOptionIds)) {
        additionalOptionIds.forEach((additionalOptionId) => {
          const additionalOption = getters.getMarketModelOptionById({
            marketModelId,
            marketModelOptionId: additionalOptionId,
          });
          const additionalOptionToIds = additionalOption?.additionalOptionsData?.parents;

          if (Array.isArray(additionalOptionToIds)) {
            additionalOptionToIds.forEach((parentId) => update(parentId));
          }

          update(additionalOptionId);
        });
      }

      if (!updateConfiguratorKey) return;
      await dispatch('setConfiguratorKeyParam', { modelId: getters.getMarketModelModelId(marketModelId) });
    },

  },
};

export default MileageYearPriceSettingsStore;
